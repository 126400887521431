import { matchPath, RouteProps } from 'react-router-dom';

import Home from './components/Home';

type PathableRouteProps = RouteProps & { path: string };

const routes: Record<string, PathableRouteProps> = {
  home: {
    path: '/',
    component: Home,
    exact: true,
  },
};

export const isKnownPath = (suspectPath: string): boolean =>
  Object.keys(routes)
    .map(key => routes[key])
    .some(route => matchPath(suspectPath, route));

export default routes;
