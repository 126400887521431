import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExternalLink from 'components/ExternalLink';
import InternalLink from 'components/InternalLink';
import React, { ComponentProps } from 'react';

export interface ListItemLinkProps {
  readonly text: string;
  readonly href: string;
  readonly color?: ComponentProps<typeof Typography>['color'];
  readonly icon?: typeof SvgIcon;
  readonly iconClassName?: string;
  readonly isExternal?: boolean;
  readonly onClick?: () => void;
}

const defaultColor = 'textPrimary';

const ListItemLink: React.FC<ListItemLinkProps> = ({
  text,
  href,
  color = defaultColor,
  icon,
  iconClassName,
  isExternal,
  onClick,
}) => {
  const Icon = icon || ArrowRightIcon;
  const LinkComponent = isExternal ? ExternalLink : InternalLink;
  const inset = !Icon;

  return (
    <ListItem component={LinkComponent} href={href} onClick={onClick} underline="none" color={color}>
      {Icon && (
        <ListItemAvatar>
          <Avatar className={iconClassName}>
            <Icon />
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText inset={inset} primary={text} />
    </ListItem>
  );
};

export default ListItemLink;
