import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const width = '100%';
const height = '100vh';

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      width,
      height,
    },
  })
);

const Content: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <Box width={width} height={height}>
        {children}
      </Box>
    </main>
  );
};

export default Content;
