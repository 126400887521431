import { Page } from '@atlaskit/embedded-confluence';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ListItemLink from 'components/ListItemLink';
import React, { useMemo } from 'react';

import photo from './me.png';

const avatarWidthDesktop = '300px';
const avatarWidthMobile = '200px';

const useStyles = makeStyles(theme =>
  createStyles({
    avatar: {
      width: 'auto',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: avatarWidthMobile,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: avatarWidthDesktop,
      },
    },
    github: {
      backgroundColor: '#24292e',
    },
    linkedin: {
      backgroundColor: '#006699',
    },
    twitter: {
      backgroundColor: '#1da1f2',
    },
    reddit: {
      backgroundColor: '#ff3300',
    },
  })
);

// mild attempt to cut down on spam
const githubUsername = atob('ZHJlYWR3YWls');
const onClickGithub = () => {
  window.location.href = `https://github.com/${githubUsername}`;
};

const Home: React.FC = () => {
  const classes = useStyles();

  const shouldDisplayEmbeddedConfluence = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return !!urlParams.get('embedded');
  }, []);

  if (shouldDisplayEmbeddedConfluence) {
    return <Page contentId="59015169" hostname="benlakey.atlassian.net" parentProduct="benlakey" spaceKey="TESTJSW" />;
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      p={1}
    >
      <Grid container justify="center">
        <Grid>
          <Box display="flex" height="100%" alignItems="center" m={1}>
            <Avatar alt="Ben" src={photo} className={classes.avatar} />
          </Box>
        </Grid>
        <Grid>
          <Box display="flex" height="100%" alignItems="center" m={1}>
            <List>
              <ListItemLink
                text="LinkedIn"
                href="https://www.linkedin.com/in/benlakey"
                isExternal={true}
                icon={LinkedInIcon}
                iconClassName={classes.linkedin}
              />
              <ListItemLink
                text="GitHub"
                onClick={onClickGithub}
                href="#"
                isExternal={true}
                icon={GitHubIcon}
                iconClassName={classes.github}
              />
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
