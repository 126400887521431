import Content from 'components/Content';
import Head from 'components/Head';
import PageRouter from 'components/PageRouter';
import React from 'react';

const Page: React.FC = () => (
  <>
    <Head />
    <Content>
      <PageRouter />
    </Content>
  </>
);

export default Page;
